import React, { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { IUsersOfApplications } from 'router/loaders/users';
import TableWithPaginator from 'components/TableWithPaginator';
import IconButton from 'components/IconButton';
import {
  MdOutlineClose,
  MdOutlineOpenInNew,
  MdOutlineSearch,
} from 'react-icons/md';
import { TextField } from '@aws-amplify/ui-react';
import Skeleton from 'components/Skeleton';
import styles from './AffiliateUsersPage.module.css';

const AffiliateUsersPage = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const { users } = useLoaderData() as {
    users: Promise<IUsersOfApplications[]>;
  };

  const filteredUsers = useMemo(async () => {
    const usersData = await users;

    if (!search) return usersData;

    return usersData.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        user.firstName.toLowerCase().includes(search.toLowerCase()) ||
        user.lastName.toLowerCase().includes(search.toLowerCase()) ||
        user.attributes.email.toLowerCase().includes(search.toLowerCase()) ||
        user.attributes.phone.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, users]);

  const tableHeaders = [
    {
      id: 'name',
      value: t('pages.habitat.affiliate.users.table.name'),
      width: '50%',
    },
    {
      id: 'email',
      value: t('pages.habitat.affiliate.users.table.email'),
      width: '20%',
    },

    {
      id: 'phone',
      value: t('pages.habitat.affiliate.users.table.phone'),
      width: '20%',
    },
    {
      id: 'view',
      value: t('pages.habitat.affiliate.users.table.view'),
    },
  ];

  const loadingCell = [
    { value: <Skeleton variation="text" numOfCharacters={20} />, id: 'name' },
    { value: <Skeleton variation="text" numOfCharacters={25} />, id: 'email' },
    { value: <Skeleton variation="text" numOfCharacters={10} />, id: 'phone' },
    {
      id: 'view',
      value: <Skeleton className={styles.buttonSkeleton} />,
    },
  ];

  return (
    <div className={styles.page}>
      <h3 className="theme-headline-medium">
        {t('pages.habitat.affiliate.users.title')}
      </h3>

      <div className={styles.searchAndTableContainer}>
        <div className={styles.searchContainer}>
          <TextField
            label
            labelHidden
            placeholder={t('pages.habitat.affiliate.users.search')}
            innerStartComponent={
              <div className={styles.search}>
                {' '}
                <MdOutlineSearch size={32} />
              </div>
            }
            innerEndComponent={
              <div className={`${search ? styles.close : styles.hidden}`}>
                <IconButton
                  variation="not-outlined"
                  className={styles.closeButton}
                  onClick={() => setSearch('')}
                >
                  <MdOutlineClose />
                </IconButton>
              </div>
            }
            className={styles.searchField}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <Suspense
          fallback={
            <TableWithPaginator
              headers={tableHeaders}
              data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
                id: number,
                cells: loadingCell,
              }))}
            />
          }
        >
          <Await resolve={filteredUsers}>
            {(resolvedUsers: IUsersOfApplications[]) => (
              <TableWithPaginator
                headers={tableHeaders}
                data={resolvedUsers.map((user: IUsersOfApplications) => ({
                  id: user.id,
                  cells: [
                    { value: `${user.firstName} ${user.lastName}`, id: 'name' },
                    { value: `${user.attributes.email}`, id: 'email' },
                    { value: `${user.attributes.phone}`, id: 'phone' },
                    {
                      id: 'view',
                      value: (
                        <div className={styles.openButtonContainer}>
                          <Link to={`${user.owner}`}>
                            <IconButton
                              className={styles.openButton}
                              variation="not-outlined"
                            >
                              <MdOutlineOpenInNew
                                size="24px"
                                color="var(--amplify-colors-neutral-90)"
                              />
                            </IconButton>
                          </Link>
                        </div>
                      ),
                    },
                  ],
                }))}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AffiliateUsersPage;
