import { Suspense, useCallback, useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Await, useLoaderData, useLocation } from 'react-router-dom';
import {
  SubmissionStatus,
  TestApplication,
  ReviewStatus,
  ApplicationTypes,
} from 'models';
import useAsync from 'hooks/utils/useAsync/useAsync';
import { Status } from 'utils/enums';
import { DataStore, SortDirection } from 'aws-amplify/datastore';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import { TCycleWithRootFormData } from 'router/loaders/cycle';
import Form from './components/Form';
import NoOpenCycle from './components/NoOpenCycle';
import SuccesfullySubmitted from './components/SuccesfullySubmitted';
import Loading from './components/Loading';
import Error from './components/Error';
import style from './ApplicantCyclePage.module.css';
import { DataProps, DISPLAY, ERROR } from './ApplicantCyclePage.types';
import Reviewed from './components/Reviewed/Reviewed';

const ApplicantCyclePage = () => {
  const { habitat } = useHabitat();

  const { cycleWithRootFormData } = useLoaderData() as {
    cycleWithRootFormData: Promise<TCycleWithRootFormData>;
  };

  const { t, i18n } = useTranslation('rootForm');

  const { user } = useAuthenticator((context) => [context.user]);

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [review, setReview] = useState(false);

  const onReview = () => {
    setActiveTab(0);
    setReview(true);
  };

  const getData = useCallback(async (): Promise<DataProps> => {
    try {
      const { cycle } = await cycleWithRootFormData;
      const applications = await DataStore.query(
        TestApplication,
        (c1) =>
          c1.and((c2) => [
            c2.testcycleID.eq(cycle.id),
            c2.ownerID.eq(user.username),
          ]),
        {
          sort: (c) => c.createdAt(SortDirection.DESCENDING),
        }
      );

      let [application] = applications;

      if (applications.length === 0) {
        if (!cycle.isOpen) {
          return {
            display: DISPLAY.NO_OPEN_CYCLE,
            data: {
              error: ERROR.CYCLE_NOT_OPEN,
            },
          };
        }
        if (sessionStorage.getItem('creatingApplication')) {
          return {
            display: DISPLAY.LOADING,
          };
        }

        sessionStorage.setItem('creatingApplication', 'true');

        const newApplication = await DataStore.save(
          new TestApplication({
            ownerID: user.username,
            lastPage: 0,
            lastSection: location.pathname,
            submissionStatus: SubmissionStatus.INCOMPLETE,
            reviewStatus: ReviewStatus.PENDING,
            submittedDate: '0001-01-01',
            testcycleID: cycle.id,
            type: ApplicationTypes.ONLINE,
            version: 0,
          })
        );
        application = newApplication;
        sessionStorage.removeItem('creatingApplication');
        return {
          display: DISPLAY.APPLICATION,
          data: {
            application,
          },
        };
      }

      const reviewed =
        application.reviewStatus === ReviewStatus.ACCEPTED ||
        application.reviewStatus === ReviewStatus.DENIED ||
        application.reviewStatus === ReviewStatus.RETURNED;

      if (reviewed || review) {
        return {
          display: DISPLAY.REVIEWED,
          data: {
            application,
          },
        };
      }

      if (application.submissionStatus === SubmissionStatus.COMPLETED) {
        return {
          display: DISPLAY.COMPLETED,
          data: {
            application,
          },
        };
      }

      if (!cycle.isOpen) {
        return {
          display: DISPLAY.NO_OPEN_CYCLE,
          data: {
            error: ERROR.CYCLE_NOT_OPEN,
            application,
          },
        };
      }

      return {
        display: DISPLAY.APPLICATION,
        data: {
          application,
        },
      };
    } catch (error) {
      return {
        display: DISPLAY.ERROR,
        data: {
          error: ERROR.UNEXPECTED_ERROR,
        },
      };
    }
  }, [cycleWithRootFormData, review, user.username, location.pathname]);

  useEffect(() => {
    const loadSupportQuestion = async () => {
      const { rootForm } = await cycleWithRootFormData;
      if (rootForm.supportQuestion) {
        i18n.addResource(
          'en',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.en
        );

        i18n.addResource(
          'es',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.es || rootForm.supportQuestion?.en
        );
      }
    };

    loadSupportQuestion();

    return () => {
      i18n.removeResourceBundle('en', 'rootForm');
      i18n.removeResourceBundle('es', 'rootForm');
    };
  }, [cycleWithRootFormData, i18n, t]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  if (status === Status.PENDING) {
    return <Loading />;
  }

  if (status === Status.REJECTED) {
    return <Error />;
  }

  if (value?.display === DISPLAY.LOADING) {
    return <Loading />;
  }

  if (value?.display === DISPLAY.ERROR) {
    return <Error error={value.data.error} />;
  }

  if (!habitat) {
    return <Error error={ERROR.HABITAT_NOT_FOUND} />;
  }

  if (value?.display === DISPLAY.REVIEWED) {
    return (
      <div className={`${style.page}`}>
        <Suspense>
          <Await resolve={cycleWithRootFormData}>
            {({
              cycle,
              rootForm,
              formSchema,
              formUrl,
            }: TCycleWithRootFormData) => (
              <Reviewed
                habitat={habitat}
                cycle={cycle}
                rootForm={rootForm}
                application={value.data.application}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                formSchema={formSchema}
                formUrl={formUrl}
              />
            )}
          </Await>
        </Suspense>
      </div>
    );
  }

  if (value?.display === DISPLAY.NO_OPEN_CYCLE) {
    return (
      <div className={`${style.page}`}>
        <Suspense>
          <Await resolve={cycleWithRootFormData}>
            {({ cycle }: TCycleWithRootFormData) => (
              <NoOpenCycle
                cycle={cycle}
                onReview={onReview}
                showReview={
                  value?.data?.application?.submissionStatus ===
                  SubmissionStatus.COMPLETED
                }
              />
            )}
          </Await>
        </Suspense>
      </div>
    );
  }

  if (value?.display === DISPLAY.COMPLETED) {
    return (
      <div className={`${style.page}`}>
        <SuccesfullySubmitted
          onReview={onReview}
          application={value.data.application}
        />
      </div>
    );
  }

  return (
    <Suspense>
      <Await resolve={cycleWithRootFormData}>
        {({ cycle, rootForm, formSchema, formUrl }: TCycleWithRootFormData) => (
          <Form
            application={value?.data?.application}
            cycle={cycle}
            rootForm={rootForm}
            formContainer={false}
            formSchema={formSchema}
            formUrl={formUrl}
            serializedHelpContent={t('supportQuestion')}
          />
        )}
      </Await>
    </Suspense>
  );
};

export default ApplicantCyclePage;
